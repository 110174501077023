import { application } from "./application"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import ScrollTo from '@stimulus-components/scroll-to'
import HwComboboxController from "@josefarias/hotwire_combobox"
import PasswordVisibility from "@stimulus-components/password-visibility"

const controllers = definitionsFromContext(require.context("controllers", true, /_controller\.js$/))
application.load(controllers)

application.register('scroll-to', ScrollTo)
application.register("hw-combobox", HwComboboxController)
application.register('password-visibility', PasswordVisibility)

document.addEventListener('turbo:before-frame-render', (event) => {
  const frameUsesViewTransitions = !!event.target.dataset.viewTransition
	
  if (document.startViewTransition && frameUsesViewTransitions) {
    const originalRender = event.detail.render

    event.detail.render = (currentElement, newElement) => {
      document.startViewTransition(() => originalRender(currentElement, newElement))
    }
  }
})
